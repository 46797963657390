html,
body {
    background-color: theme('colors.white');
    color: theme('colors.gray.700');
    scroll-behavior: smooth;
}

.MainContent {
    @media screen and (min-width: theme('screens.xl')) {
        width: calc(80% - 5rem); // left-sidebar = 20%, 5rem = right bar (section indicators)
    }

    @media screen and (min-width: theme('screens.xxxl')) {
        width: calc(83.333333% - 5rem); // left-sidebar = 20%, 5rem = right bar (section indicators)
    }
}

.MainMenuMobile {
    height: calc(100vh - 5rem);
    overflow: scroll;
}

[x-cloak] {
    display: none !important;
}