.NewsSingle {

    .social-links {
        &-list {
            opacity: 0;
            transform: translateX(-1rem);
            transition: opacity 0.3s, transform 0.3s;
            transition-timing-function: theme('transitionTimingFunction.in-out');
            pointer-events: none;

            &.is-visible {
                display: inline-flex;
                opacity: 1;
                transform: translate(0);
                pointer-events: all;
            }
        }

        &-label:hover .social-links-list {
            opacity: 1;
            transform: translate(0);
            pointer-events: all;
        }
    }
}