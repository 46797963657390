@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'import-fonts';
@import 'base';
@import 'headlines';
@import 'buttons';
@import 'links';
@import 'news';
@import 'sections';
@import 'editables';
@import 'components';
@import 'areabricks';
@import 'ribbon';
@import 'overlays';
@import 'slider'; // includes swiper
@import 'wysiwyg';
@import 'forms';
@import 'print';
@import 'plyr';
