.DisturbanceInfoBanner {
    background-color: theme('colors.red.500');
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0MCcgaGVpZ2h0PSc0MCc+CjxyZWN0IHdpZHRoPSc0MCcgaGVpZ2h0PSc0MCcgZmlsbD0nI2NhMzIyZicvPgo8cGF0aCBkPSdNLTQsNCBsOCwtOAogICAgICAgICAgIE0wLDQwIGw0MCwtNDAKICAgICAgICAgICBNMzYsNDQgbDgsLTgnIHN0cm9rZT0nI2RjMmYzNCcgc3Ryb2tlLXdpZHRoPScxMicvPgo8L3N2Zz4=);

    &.play {
        .text-content {
            animation: scrolling 10s linear infinite;
            animation-delay: 1s;
        }
    }
}

@keyframes scrolling {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}
