@use "sass:math";

.Editor {
    word-wrap: break-word;
    overflow-wrap: break-word;

    -webkit-hyphens: auto;
       -moz-hyphens: auto;
            hyphens: auto;

    &.has-gray-headlines {
        h2,
        h3,
        h4,
        h5 {
            @apply tw-text-gray-700;
        }
    }

    h2:first-child,
    h3:first-child,
    h4:first-child {
        margin-top: 0 !important;
    }

    p + p {
        margin-top: 1.5rem;
    }

    a {
        @apply tw-text-brand;

        &:hover {
            color: theme('colors.blue.400');
        }
    }

    ul,
    ol {
        @apply tw-list-outside tw-mb-6 tw-ml-5;

        ul,
        ol {
            @apply tw-ml-6;
        }
    }

    ol {
        @apply tw-list-decimal;
    }

    ul {
        @apply tw-list-disc;
    }

    ul.has-custom-listing {
        li {
            @apply tw-pl-2;
        }
    }

    ul.is-checkmark-list {
        list-style-type: '✓';
    }

    h2 {
        @apply tw-font-black tw-text-3xl tw-mt-16 tw-mb-12 tw-text-gray-700;

        line-height: #{math.div(50, 50)};

        @media screen and (min-width: theme('screens.md')) {
            font-size: 2.5rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 3.125rem;
        }
    }

    h3 {
        @apply tw-font-black tw-text-2xl tw-mt-12 tw-mb-8 tw-text-gray-700;

        line-height: #{math.div(48, 40)};

        @media screen and (min-width: theme('screens.md')) {
            font-size: 2rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 2.5rem;
        }
    }

    h4 {
        @apply tw-font-black tw-text-base tw-mt-8 tw-mb-4 tw-text-gray-700;
        @apply md:tw-text-lg;
        @apply lg:tw-text-2xl;

        line-height: #{math.div(32, 24)};
    }

    .ArrowLink {
        @apply tw-relative tw-inline-flex tw-items-center tw-text-brand tw-mr-4 hover:tw-text-blue-400;

        &::after {
            @apply tw-ml-2 tw-relative tw-block;

            content: '\2192';
            transition: transform 0.3s theme('transitionTimingFunction.out');
        }

        &:hover::after {
            transform: translateX(0.5rem);
        }
    }

    .text-small {
        font-size: 75%;
    }
}
