.MainmenuOverlay {
    left: 20%;
    z-index: 15;

    &.is-editmode {
        left: 30%;
    }

    @media screen and (min-width: theme('screens.xxxl')) {
        left: 16.666667%;
    }
}

.has-overlay {
    &::before {
        @apply tw-block tw-bg-blue-900 tw-opacity-0 tw-fixed tw-inset-0 tw-transition-opacity tw-duration-500 tw-delay-300 tw-pointer-events-none;

        content: '';
        z-index: 12;
    }

    &.overlay-active {
        &::before {
            @apply tw-opacity-80 tw-pointer-events-auto;
            transition-delay: 0s;
        }
    }
}