.Ribbon {
    @apply tw-relative tw-inline-block tw-text-white tw-py-2 tw-pl-4 tw-mb-4;

    left: -20px;
    top: -18px;
    font-weight: 600;
    padding-right: 60px;
    letter-spacing: 0.1px;
    color: theme('colors.white');
    background: linear-gradient(-45deg, transparent, transparent 20%, theme('colors.red.500') 0%, theme('colors.red.500'));

    &.is-big {
        @apply tw-py-2 tw-pl-6 xl:tw-py-4;
        padding-right: 90px;
    }
}

.Ribbon::before {
    @apply tw-absolute tw-left-0;

    content: " ";
    z-index: 2;
    bottom: 100%;
    border: 8px solid transparent;
    border-right: 8px solid theme('colors.red.800');
    border-bottom: 8px solid theme('colors.red.800');
}