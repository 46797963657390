@media print {
    html {
        font-size: 10px;
    }

    .Headline {
        margin-top: 20px;
    }

    .tw-container {
        width: 100%;
        max-width: none;
    }

    .signature {
        display: block
    }

    .menu-toggle,
    .footer-links,
    .Footer,
    .fb-tabs,
    .fb-navigation,
    .fb-messages {
        display: none;
    }



    .ContentInner > *:last-child {
        margin-top: 0rem;
    }

    .AB-Form {
        .Form {
            padding-top: 0;

            .formbuilder {
                padding: 0;
            }
        }
    }
}
