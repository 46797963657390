form {
    label {
        @apply tw-text-sm;

        &.required {
            &::after {
                content: "*";
            }
        }

        a {
            @apply tw-text-brand;
            text-decoration: underline;
        }
    }

    input,
    textarea,
    select {
        @apply tw-px-5 tw-py-3 tw-bg-white tw-leading-normal;
    }

    .radio,
    .checkbox {
        @apply tw-relative tw-pl-7;

        input {
            @apply tw-hidden;
        }

        label {
            @apply tw-text-base;
            cursor: pointer;

            &::before,
            &::after {
                @apply tw-absolute tw-left-0 tw-block tw-w-5 tw-h-5;
                content: "";
            }

            &::before {
                border: 1px solid theme('colors.gray.700');
            }

            &::after {
                @apply tw-top-0 tw-bg-white tw-ease-in-out tw-duration-300;
                background: url(../images/icons/checkmark.svg) center theme('colors.blue.500') no-repeat;
                background-size: 60%;
                content: "";
                opacity: 0;
                visibility: hidden;
                transition-property: visibility, opacity;
            }
        }

        input:checked ~ label {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        &-custom {
            @apply tw-text-sm;
        }
    }

    .radio {
        label {
            &::before,
            &::after {
                border-radius: 50%;
            }
        }
    }

    .fb-row {
        &.has-error {
            > label {
                color: theme('colors.red.500');
            }

            > input, select, textarea {
                border-bottom: 2px solid theme('colors.red.500');
            }
        }

        .error-message {
            @apply tw-text-xs tw-mt-1;
            color: theme('colors.red.500');
        }

        .error-icon {
            @apply tw-absolute tw-top-10 tw-right-5 tw-w-4 tw-h-4;
            background: url(../images/icons/error.svg) center no-repeat;
            background-size: contain;
        }
    }

    .fb-collapse,
    .fb-content {
        ul, ol {
            @apply tw-mt-3;
        }

        ul {
            li {
                @apply tw-relative tw-pl-3;

                &::before {
                    @apply tw-absolute tw-left-0 tw-w-1 tw-h-1 tw-bg-gray-700;
                    content: "";
                    border-radius: 50%;
                    top: 0.5rem;
                }
            }
        }

        ol {
            counter-reset: section;

            li {
                counter-increment: section;

                &:before {
                    content: counter(section) ". ";
                    top: auto;
                    border: none;
                }
            }
        }

        a {
            color: theme('colors.brand');
        }
    }

    .formbuilder {
        &-container {
            &-block-label {
                @apply tw-mt-5 tw-text-left tw-text-sm tw-font-bold;
            }
        }
    }

    .qq {
        &-uploader {
            @apply tw-static;
        }

        &-upload {
            &-container {
                @apply tw-px-5 tw-py-5 tw-bg-white tw-relative;
            }

            &-button {
                @apply tw-px-4 tw-inline-block;
                width: auto;
                background: theme('colors.brand') !important;
            }

            &-list {
                li {
                    @apply tw-relative tw-p-3 tw-mt-3;
                    background: theme('colors.white') !important;
                    color: theme('colors.gray.700') !important;
                }
            }

            &-size {
                color: theme('colors.gray.500');
            }

            &-delete {
                color: theme('colors.red.500') !important;
            }

            &-retry,
            &-cancel {
                color: theme('colors.orange.500') !important;
            }

            &-status-text {
                @apply tw-absolute tw-right-5 tw-text-xs;
            }

            &-spinner {
                @apply tw-hidden;
            }
        }

        &-btn {
            @apply tw-absolute tw-right-5;
        }

        &-progress {
            &-bar {
                @apply tw-absolute tw-top-0 tw-left-0 tw-h-1;
                border-radius: 0;
                background: theme('colors.brand') !important;
            }
        }
    }

    .add-block,
    .remove-block {
        outline: none;
        box-shadow: none;
    }

    .add-block {
        @apply tw-text-base;
        display: inline-flex !important;
    }

    .remove-block {
        @apply tw-block;
        margin-top: -1rem;
    }
}