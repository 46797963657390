.pimcore_editable_image {
    width: 100% !important;
    height: 100% !important;

    &.image-cover {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.pimcore_editable_image img {
    width: 100%;
    height: auto;
}

.pimcore_editable_areablock > *:not(:first-child):not([type="home-header"]) {
    @apply tw-my-20;
}