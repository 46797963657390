.ListIconText {
    li {
        position: relative;

        &:hover {
            &::before {
                transform: scaleY(1);
                transform-origin: top;
                opacity: 1;
            }
        }

        &::before {
            @apply tw-block tw-absolute tw--inset-y-px tw-inset-x-0 tw-pointer-events-none tw-bg-white;

            content: '';
            transition: transform 0.5s theme('transitionTimingFunction.out'), opacity 0.5s theme('transitionTimingFunction.out');
            transform-origin: bottom;
            transform: scaleY(0);
            opacity: 0;
        }
    }
}