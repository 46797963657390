@import 'swiper/scss'; // import swiper core styling
@import 'swiper/scss/pagination'; // import pagination component

.Swiper.Swiper.Swiper {
    overflow: visible !important;
    padding-bottom: 4rem;
    position: relative;

    .is-news-carousel {
        padding-bottom: 5rem;
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background: theme('colors.blue.200');

        &-active {
            background: theme('colors.blue.900');
        }
    }
}