.Button {
    @apply tw-text-lg tw-px-4 tw-py-2 md:tw-px-8 md:tw-py-4;

    &.is-primary {
        @apply tw-text-white tw-bg-brand tw-font-black hover:tw-bg-blue-400;
    }

    &.is-secondary {
        @apply tw-text-white tw-bg-blue-900 tw-font-black hover:tw-bg-blue-800;
    }

    &.is-big {
        @apply tw-px-8 tw-py-4 lg:tw-px-12 lg:tw-py-5 lg:tw-text-3xl;
    }
}