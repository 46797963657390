@use "sass:math";

.Headline {
    @apply tw-text-blue-900;

    &.is-size-1 {
        @apply tw-font-black tw-text-4xl;

        line-height: #{math.div(70, 70)};

        @media screen and (min-width: theme('screens.md')) {
            font-size: 3.5rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 3.375rem;
        }

        @media screen and (min-width: theme('screens.xxl')) {
            font-size: 3.5rem;
        }

        @media screen and (min-width: theme('screens.xxxl')) {
            font-size: 4.375rem;
        }
    }

    &.is-size-2 {
        @apply tw-font-black tw-text-3xl;

        line-height: #{math.div(50, 50)};

        @media screen and (min-width: theme('screens.md')) {
            font-size: 2.5rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 3.125rem;
        }
    }

    &.is-size-3 {
        @apply tw-font-black tw-text-2xl;

        line-height: #{math.div(48, 40)};

        @media screen and (min-width: theme('screens.md')) {
            font-size: 2rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 2.5rem;
        }
    }

    &.is-size-4 {
        line-height: #{math.div(48, 40)};
    }

    &.is-size-5 {
        @apply tw-font-black tw-text-base;
        @apply md:tw-text-xl;
        @apply lg:tw-text-3xl;

        line-height: #{math.div(36, 30)};
    }

    &.is-size-6 {
        @apply tw-font-black tw-text-base;
        @apply md:tw-text-lg;
        @apply lg:tw-text-2xl;

        line-height: #{math.div(32, 24)};
    }

    &.is-white {
        @apply tw-text-white;
    }

    &.is-gray {
        @apply tw-text-gray-700;
    }

    &.do-inherit-color {
        color: inherit !important;
    }

    &.is-product-title {
        @media screen and (min-width: theme('screens.md')) {
            font-size: 2.5rem;
        }

        @media screen and (min-width: theme('screens.xl')) {
            font-size: 2rem;
        }

        @media screen and (min-width: theme('screens.xxl')) {
            font-size: 1.6725rem;
        }

        @media screen and (min-width: theme('screens.max')) {
            font-size: 2.25rem;
        }
    }
}
