.ListSimpleIconLink {
    position: relative;

    &.has-hover-transition {
        li {
            position: relative;
            transition: transform 0.4s theme('transitionTimingFunction.out');
        }

        &:hover {
            li {
                transform: translateX(-12.5%);
            }

            li:hover {
                transform: scale(1.25);
                transition-duration: 0.2s/1.1;

                h3 {
                    color: theme('colors.blue.400');
                }
            }

            li:hover ~ li {
                transform: translateX(12.5%);
            }
        }
    }
}