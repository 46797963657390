.ArrowLink,
.IconLink {
    @apply tw-inline-flex tw-items-center tw-text-brand hover:tw-text-blue-400;
    @apply focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400;

    &.has-large-click-area:not(.has-arrow-left) {
        &::before {
            @apply tw-absolute tw-block tw-inset-0;

            content: '';
        }
    }

    &.has-large-click-area:not(.has-arrow-right) {
        &::after {
            @apply tw-absolute tw-block tw-inset-0;

            content: '';
        }
    }

    &.has-arrow-right {
        @apply tw-mr-4;

        &::after {
            @apply tw-ml-2 tw-relative tw-block;

            content: '\2192';
            transition: transform 0.3s theme('transitionTimingFunction.out');
        }

        &:hover::after {
            transform: translateX(0.5rem);
        }
    }

    &.has-arrow-left {
        @apply tw-ml-4;

        &::before {
            @apply tw-mr-2 tw-relative tw-block;

            content: '\2190';
            transition: transform 0.3s theme('transitionTimingFunction.out');
        }

        &:hover::before {
            transform: translateX(-0.5rem);
        }
    }

    &.has-plus-right {
        @apply tw-mr-4;

        &::after {
            @apply tw-ml-2 tw-relative tw-block;

            content: '+';
            transition: transform 0.3s theme('transitionTimingFunction.out');
        }

        &:hover::after {
            transform: translateX(0.5rem);
        }
    }
}

.Hoverlist {
    li {
        @apply tw-transition-opacity tw-ease-in-out tw-duration-300;
    }

    &:hover li{
        @apply tw-opacity-60;

        &:hover{
            @apply tw-opacity-100;
        }
    }
}