@import "dropzone/dist/dropzone.css";

.Form {
    @apply tw-pt-10 lg:tw-pt-20;

    .formbuilder {
        @apply tw-p-3 lg:tw-p-10;
        background: theme('colors.white');

        input,
        textarea,
        select {
            @apply tw-bg-gray-200 placeholder:tw-text-gray-700/50;

            &[disabled] {
                opacity: 0.5;
            }
        }

        .ts {
            &-dropdown {
                @apply tw-bg-gray-200 tw-border-none;

                .active {
                    @apply tw-bg-brand;
                    color: theme('colors.white');
                }
            }

            &-wrapper.single {
                &,
                &.input-active {
                    .ts-control {
                        @apply tw-px-5 tw-py-3 tw-bg-gray-200 tw-leading-normal tw-border-none;
                        border-radius: 0;

                        &:active,
                        &:focus {
                            @apply tw-bg-gray-200;
                        }

                        .item,
                        input {
                            @apply xxxl:tw-text-lg;
                        }
                    }
                }
            }
        }

        .disabled {
            .ts-control {
                opacity: 0.5;
            }
        }

        >.fb-row,
        .fb-hide {
            @apply tw-hidden;
        }

        .fb-hide {
            display: none !important;
        }

        >.fb-row {
            @apply tw-relative;

            &.active {
                @apply tw-block;
            }
        }

        .fb-button-prev,
        .fb-button-next,
        .fb-button-submit {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

        .fb-button-submit {
            @apply tw-hidden;
        }

        &.show-next .fb-button-next,
        &.show-prev .fb-button-prev,
        &.show-final .fb-button-submit {
            pointer-events: all;
            cursor: pointer;
            opacity: 1;
        }

        &.show-final {
            .fb-button-submit {
                @apply tw-inline-block;
            }

            .fb-button-next {
                @apply tw-hidden;
            }
        }

        .qq {
            &-upload {
                &-container {
                    @apply tw-px-5 tw-py-3 tw-bg-gray-200;
                }
            }
        }
    }

    .fb-tabs {
        @apply tw-ease-in-out tw-duration-300 tw-z-10;
        background: transparent;
        transition-property: background, padding-left, padding-right;

        li {
            @apply tw-hidden tw-relative tw-transition-opacity tw-ease-in-out tw-duration-300 tw-pr-20 lg:tw-block;
            border-bottom: 3px solid theme('colors.gray.700');
            line-height: 3em;
            opacity: 0.6;

            .number {
                background: theme('colors.black');
                border-radius: 50%;
                border: 2px solid transparent;
                color: theme('colors.white');
                top: 50%;
                transform: translateY(-50%);

                &::before {
                    @apply tw-absolute;
                    content: attr(data-counter);
                    counter-increment: section;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &.active {
                @apply tw-block;
                opacity: 1;

                .number {
                    background: transparent;
                    border-color: theme('colors.gray.700');
                    color: theme('colors.black');
                }
            }

            &.has-error {
                &, .number {
                    color: theme('colors.red.500');
                    border-color: theme('colors.red.500');
                }
            }

            &.done {
                a {
                    pointer-events: all;
                    cursor: pointer;
                }

                &::after {
                    @apply tw-absolute tw-right-10 tw-block tw-h-5 tw-w-5;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "";
                    background: url(../images/icons/checkmark-black.svg) center no-repeat;
                    background-size: contain;
                }

                &.fb-tab-chapter-summary {
                    &::after {
                        content: none;
                    }
                }
            }

            &:last-child {
                @apply tw-pr-0;
            }
        }

        &::after {
            @apply tw-absolute tw-block tw-bottom-0 tw-left-0 tw-right-0 tw-w-full tw-z-10;
            background: theme('colors.gray.300');
            content: "";
            height: 3px;
        }

        &.is-sticky {
            background: theme('colors.white');
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .fb-summary {
        &-chapter {
            @apply tw-py-5;
            border-bottom: 1px solid theme('colors.gray.500');
        }

        &-item {
            @apply tw-py-2;
            border-bottom: 1px dotted theme('colors.gray.500');
        }
    }

    .fb-navigation {
        @apply tw-pt-10;
    }

    .fb-calculation {
        @apply tw-my-12 tw-py-8;
        border-top: 1px solid theme('colors.gray.500');
        border-bottom: 1px solid theme('colors.gray.500');

        .tw-grid {
            >div {
                @apply tw-pl-5;
                border-left: 1px solid theme('colors.gray.500');

                &:first-child {
                    @apply tw-pl-0;
                    border: none;
                }
            }
        }
    }
}