.ProductGrid {
    .Ribbon {
        top: 0;
    }

    .ProductGrid-content {
        display: none;
    }

    .ProductGrid-content-trigger.open {
        .ProductGrid-content-trigger-arrow {
            transform: rotate(180deg);
        }

        + .ProductGrid-content {
            display: block;
        }
    }
}
