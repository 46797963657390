.ContentInner {
    > *:not(:first-child):not(:last-child) {
        @apply tw-my-10;
        @apply md:tw-my-14;
        @apply xxl:tw-my-20;
    }

    > *:first-child {
        @apply tw-mb-10;
        @apply md:tw-mb-14;
        @apply xxl:tw-mb-20;
    }

    > *:last-child {
        @apply tw-mt-10;
        @apply md:tw-mt-14;
        @apply xxl:tw-mt-20;
    }

    > *.has-no-mt {
        margin-top: 0 !important;
    }

    > *.has-no-mb {
        margin-bottom: 0 !important;
    }
}

.OutdatedInfo {
    background: #dc2f34;
    color: #fff;
}