@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/6288193/54cd418c-d829-40d8-87cb-60132155501c.woff2') format('woff2'),
        url('../fonts/6288193/1ee2e0d8-ea43-407d-b037-a7d4d89890b9.woff') format('woff');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/6237457/04376105-33eb-4368-8027-ceb0a1dc8ced.woff2') format('woff2'),
        url('../fonts/6237457/6ce13c54-7d23-4b2d-8647-9bceaf4a208a.woff') format('woff');
}

