.SectionNavigation {
    .navigation:hover {
        .detaillist {
            pointer-events: all;
            opacity: 1;
        }
    }

    .detaillist {
        pointer-events: none;
        opacity: 0;
    }
}